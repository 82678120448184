<template>
  <VModal
    size="modal-xl"
    @click:cancel="$emit('click:cancel')"
    @click:confirm="$emit('click:confirm')"
  >
    <template #header>
      <div
        class="flex justify-between items-center p-4 border-b border-gray-200 dark:border-dark-5"
      >
        <span class="font-medium text-lg">
          <slot name="header" />
        </span>

        <div class="cursor-pointer" @click="$emit('click:cancel')">
          <XIcon class="w-8 h-8 text-gray-500" />
        </div>
      </div>
    </template>

    <template #content>
      <slot name="content" />
    </template>
  </VModal>
</template>

<script>
// Components
import VModal from "@/components/modals/VModal";

export default {
  components: {
    VModal
  }
};
</script>
