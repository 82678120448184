<template>
  <div>
    <VTitle
      :title="$t('app.expiring_competencies', 2)"
      class="overview-title"
    />

    <VAlert
      v-if="displayedRows.length === 0"
      :text="$t('app.no_expiring_competencies')"
    />

    <div v-else>
      <VCard
        v-for="(item, index) in displayedRows"
        :key="index"
        :class="[
          { 'mt-4': index !== 0 },
          item.isExpired ? 'expired' : 'expiring'
        ]"
      >
        <template #content>
          <div class="flex justify-between items-start">
            <div class="flex w-1/2 items-center">
              <VImage
                :src="item.displayed_avatar"
                :name="item.displayed_name"
                class="mr-2"
              />

              <div>
                <div class="font-medium">
                  {{ item.displayed_name }}
                </div>

                <div class="font-light text-xs">
                  @{{ item.displayed_username }}
                </div>
              </div>
            </div>

            <div class="flex w-1/2 items-start">
              <div class="w-1/2 mr-5">
                <VLine>
                  <template #label>
                    <div class="uppercase font-light text-2xs">
                      {{ $t("app.competencies") }}
                    </div>
                  </template>
                </VLine>

                {{ item?.competency_name }}
              </div>

              <div class="w-1/2">
                <VLine>
                  <template #label>
                    <div class="uppercase font-light text-2xs">
                      {{ $t("app.expires_on") }}
                    </div>
                  </template>
                </VLine>

                {{ item?.displayed_date }}
              </div>
            </div>
          </div>
        </template>
      </VCard>

      <VButton
        :label="$t('app.view_all')"
        class="btn-outline-secondary w-full mt-4"
        @click="viewAll"
      />
    </div>

    <DisplayModal v-if="isModalVisible" id="modal" @click:cancel="hideModal">
      <template #header>
        {{ $t("app.expiring_competencies", 2) }}
      </template>

      <template #content>
        <VList
          :rows="displayedData"
          :headers="headers"
          hide-header
          :is-loading="isLoading"
        >
          <!-- eslint-disable-next-line -->
          <template #item.image="{ item }">
            <VImage :src="item.displayed_avatar" :name="item.displayed_name" />
          </template>

          <!-- eslint-disable-next-line -->
          <template #item.user="{ item }">
            <div>
              <div class="font-medium whitespace-nowrap">
                {{ item.displayed_name }}
              </div>

              <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
                @{{ item.displayed_username }}
              </div>
            </div>
          </template>

          <!-- eslint-disable-next-line -->
          <template #item.source="{ item }">
            <div>
              <div class="font-medium whitespace-nowrap">
                {{ item.source_name }}
              </div>

              <div class="version mt-0.5">
                {{ $t("app.versions") }} {{ item.source?.version }}
              </div>
            </div>
          </template>

          <!-- eslint-disable-next-line -->
          <template #item.expires_on="{ item }">
            {{ item.displayed_date }}
          </template>

          <!-- eslint-disable-next-line -->
          <template #item.status="{ item }">
            <div class="flex justify-center">
              <VChip
                :text="item.displayed_status"
                :class="getStatusColor(item.status)"
              />
            </div>
          </template>
        </VList>
      </template>
    </DisplayModal>
  </div>
</template>

<script>
import { inject, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import useColor from "@/composables/useColor";
import useModal from "@/composables/useModal";
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
// Components
import VCard from "@/components/VCard";
import VImage from "@/components/VImage";
import VAlert from "@/components/VAlert";
import VLine from "@/components/VLine";
import VChip from "@/components/VChip";
import VTitle from "@/components/VTitle";
import VButton from "@/components/VButton";
import VList from "@/components/tables/VList";
import DisplayModal from "@/components/modals/DisplayModal";

export default {
  components: {
    VTitle,
    VChip,
    VCard,
    VImage,
    VAlert,
    VLine,
    VButton,
    DisplayModal,
    VList
  },
  props: {
    competencies: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // Misc
    const moment = inject("moment");
    const { t } = useI18n();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.users"),
        value: "user"
      },
      {
        text: t("app.competencies"),
        value: "competency_name",
        class: "align-top"
      },
      {
        text: t("app.source"),
        value: "source"
      },
      {
        text: t("app.expires_on"),
        value: "expires_on",
        class: "align-top"
      },
      {
        text: t("app.status"),
        value: "status",
        class: "w-40"
      }
    ];

    // Composables
    const { isLoading, data, read } = useReadMultiple();
    const { isModalVisible, showModal, hideModal } = useModal();
    const { getText } = useTexts();
    const { getAvatarURL, formatDate } = useDisplay();
    const { getStatusColor } = useColor();

    // Computed
    const displayedRows = computed(() => {
      return props.competencies.map(competency => ({
        ...competency,
        displayed_avatar: getAvatarURL(competency?.learner?.avatar?.view_path),
        // eslint-disable-next-line
        displayed_name: `${competency?.learner?.firstname ?? ""} ${competency?.learner?.lastname ?? ""}`,
        displayed_username: competency?.learner?.username,
        competency_name: getText(competency?.competency?.texts, "name"),
        displayed_date: formatDate({ date: competency?.expires_on }),
        isExpired: isExpired(competency.expires_on)
      }));
    });

    const displayedData = computed(() => {
      return data.value.map(x => ({
        ...x,
        displayed_avatar: getAvatarURL(x?.learner?.avatar?.view_path),
        // eslint-disable-next-line
        displayed_name: `${x?.learner?.firstname ?? ""} ${x?.learner?.lastname ?? ""}`,
        displayed_username: x?.learner?.username,
        competency_name: getText(x?.competency?.texts, "name"),
        displayed_date: formatDate({ date: x?.expires_on }),
        source_name: getText(x?.source?.texts, "name"),
        displayed_status: t(`app.${x.status}`)
      }));
    });

    // Methods
    const isExpired = expiredDate => {
      const today = new Date();

      return moment(today).isSameOrAfter(expiredDate);
    };

    const viewAll = async () => {
      await read({
        endpoint: "teaching.dashboard.expiring-competencies",
        showToaster: false
      });

      showModal();
    };

    return {
      headers,
      displayedRows,
      viewAll,
      // useModal
      isModalVisible,
      showModal,
      hideModal,
      // useReadMultiple
      isLoading,
      displayedData,
      // useColor
      getStatusColor
    };
  }
};
</script>

<style lang="scss">
.expired {
  $color: rgb(220, 38, 38);

  border-left: 5px solid $color;
}
.expiring {
  $color: rgb(250, 204, 21);

  border-left: 5px solid $color;
}
</style>
