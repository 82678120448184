<template>
  <div>
    <VTitle :title="$t('app.scheduled_observations')" class="overview-title" />

    <VCalendar :markers="markers">
      <template #marker="{ marker }">
        <VImage :src="marker?.avatar" :name="marker?.name" class="mr-2" />

        <div>
          <div class="font-medium truncate">
            {{ marker.name }}
          </div>

          <div class="truncate">
            {{ marker.evaluation_name }}
          </div>
        </div>

        <div
          class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"
        />

        <span class="font-medium xl:ml-auto">
          {{ formatDate({ date: marker.date }) }}
        </span>
      </template>
    </VCalendar>
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useTexts from "@/composables/useTexts";
import useDisplay from "@/composables/useDisplay";
// Components
import VCalendar from "@/components/VCalendar";
import VImage from "@/components/VImage";
import VTitle from "@/components/VTitle";

export default {
  components: {
    VCalendar,
    VImage,
    VTitle
  },
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    // Composables
    const { getAvatarURL, formatDate } = useDisplay();
    const { getText } = useTexts();

    // Computed
    const markers = computed(() => {
      return props.events.map(event => ({
        ...event,
        date: event?.observation_scheduled_on,
        avatar: getAvatarURL(event?.registration?.learner?.avatar?.view_path),
        // eslint-disable-next-line
        name: `${event?.registration?.learner?.firstname ?? ""} ${event?.registration?.learner?.lastname ?? ""}`,
        evaluation_name: getText(event?.evaluation?.texts, "name")
      }));
    });

    return {
      markers,
      formatDate
    };
  }
};
</script>
