<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle :title="$t('app.dashboard')" class="route-title" />

      <Tippy tag="a" class="tooltip" :content="$t('app.refresh_content')">
        <RefreshCcwIcon
          class="text-theme-1 cursor-pointer"
          @click="refreshDashboard"
        />
      </Tippy>
    </div>

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else class="grid grid-cols-3 gap-8">
      <div class="col-span-3 xl:col-span-2">
        <Summary :summary="data?.summary" class="mb-8" />

        <div class="mt-8">
          <VTitle :title="$t('app.results', 2)" class="overview-title" />

          <div class="grid grid-cols-8 gap-4 mt-4">
            <Status
              v-for="(value, key, index) in data.results"
              :key="index"
              :title="$t(`backend.${key}`)"
              :status="value"
              class="col-span-8 sm:col-span-8 lg:col-span-4"
            />
          </div>
        </div>

        <div class="mt-8">
          <VTitle :title="$t('app.statuses', 2)" class="overview-title" />

          <div class="grid grid-cols-12 gap-4 mt-4">
            <Status
              v-for="(value, key, index) in data.statuses"
              :key="index"
              :chart-type="chartTypes.DONUT"
              :title="$t(`backend.${key}`)"
              :status="value"
              class="col-span-12 sm:col-span-12 lg:col-span-6 2xl:col-span-4"
            />
          </div>
        </div>

        <Lists class="mt-8" :data="data" />
      </div>

      <div class="col-span-3 xl:col-span-1">
        <Events class="mb-8" :events="data.observations_this_month" />

        <ExpiringCompetencies
          class="mb-8"
          :competencies="data.expiring_competencies"
        />

        <LatestFeedback :feedback="data.latest_feedback" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import Summary from "./Summary";
import LatestFeedback from "@/components/templates/LatestFeedback";
import Lists from "./Lists";
import Status from "./Status";
import ExpiringCompetencies from "./ExpiringCompetencies";
import Events from "./Events";
// Constants
import chartTypes from "@/constants/chartTypes";

export default {
  components: {
    VTitle,
    Summary,
    LatestFeedback,
    Lists,
    Status,
    ExpiringCompetencies,
    Events
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Data
    const data = ref({});
    const isLoading = ref(false);

    // Composables
    const { request } = useRequest();

    // Constants
    const documentTitle = `${t("app.dashboard")} - ${t("app.teaching")}`;

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "teaching.dashboard",
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};
      isLoading.value = false;
    };

    const refreshDashboard = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "teaching.dashboard.refresh",
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};
      isLoading.value = false;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      chartTypes,
      refreshDashboard,
      documentTitle,
      data,
      getData,
      isLoading
    };
  }
};
</script>
