<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle :title="title" class="overview-title" />

      <div
        class="boxed-tabs nav nav-tabs justify-end bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-500 rounded-md"
        role="tablist"
      >
        <VTab
          :name="TABS.COLLECTIONS"
          :selected-tab="currentTab"
          :label="$t('app.collections', 2)"
          class="btn border-0 shadow-none py-1.5 px-10"
          lowercase-label
          @click="updateTab"
        />

        <VTab
          :name="TABS.TRAININGS"
          :selected-tab="currentTab"
          :label="$t('app.trainings', 2)"
          class="btn border-0 shadow-none py-1.5 px-10 end-tab"
          lowercase-label
          @click="updateTab"
        />
      </div>
    </div>

    <Completions
      v-if="currentTab === TABS.TRAININGS"
      :completions="data?.training_completions"
      class="mb-8"
    />

    <Completions
      v-if="currentTab === TABS.COLLECTIONS"
      :completions="data?.collection_completions"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTabs from "@/composables/useTabs";
// Components
import VTab from "@/components/VTab";
import VTitle from "@/components/VTitle";
import Completions from "./Completions";

export default {
  components: {
    VTab,
    VTitle,
    Completions
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    // Misc
    const { t } = useI18n();

    // Constants
    const TABS = {
      TRAININGS: "trainings",
      COLLECTIONS: "collections"
    };

    // Composables
    const { currentTab, updateTab } = useTabs(TABS.TRAININGS);

    // Computed
    const title = computed(() => t(`app.${currentTab.value}`, 2));

    return {
      TABS,
      title,
      // useTabs
      currentTab,
      updateTab
    };
  }
};
</script>
