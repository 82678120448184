<template>
  <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3">
    <div :class="{ 'intro-y': hasAnimation }">
      <div class="box p-5">
        <div class="flex items-center">
          {{ $t("app.last_28_days") }}
        </div>

        <div class="text-2xl font-medium mt-2">
          {{ completions.last_28_days }}
        </div>

        <div class="mt-8">
          <HorizontalBar :data-sets="dataSets" :labels="labels" />
        </div>

        <div
          class="text-gray-600 dark:text-gray-600 text-xs flex mb-2 pb-2 mt-4"
        >
          {{ $t("app.top_trainings", 2) }}
        </div>

        <div
          v-for="(training, index) in completions.top_learner_completions"
          :key="index"
          class="flex justify-between items-center"
        >
          <div>
            <!-- @TODO: fix -->
            {{ training.title.en }}
          </div>

          <div>
            {{ formatAmount(training.completions, 0) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useAnimation from "@/composables/useAnimation";
import useDisplay from "@/composables/useDisplay";
// Components
import HorizontalBar from "@/components/charts/horizontal-bar-chart/Main";
// Constants
import dateFormats from "@/constants/dateFormats";

export default {
  components: {
    HorizontalBar
  },
  props: {
    completions: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { formatAmount, formatDate } = useDisplay();
    const { hasAnimation } = useAnimation();

    // Computed
    const dailies = computed(() => {
      return props.completions?.daily_completions_and_approvals ?? {};
    });

    const labels = computed(() => {
      return Object.keys(dailies.value).map(daily => {
        return formatDate({
          date: daily,
          format: dateFormats.HUMAN_SHORTENED_DATE_FORMAT
        });
      });
    });

    const completed = computed(() => {
      return Object.values(dailies.value).map(daily => {
        return daily.learner_completed;
      });
    });

    const approved = computed(() => {
      return Object.values(dailies.value).map(daily => {
        return daily.completion_approved;
      });
    });

    const dataSets = computed(() => {
      return [
        {
          label: t("app.learner_completed"),
          barPercentage: 1,
          barThickness: 10,
          maxBarThickness: 10,
          minBarThickness: 2,
          data: completed.value,
          backgroundColor: "#3160D8"
        },
        {
          label: t("app.completion_approved"),
          barPercentage: 1,
          barThickness: 10,
          maxBarThickness: 10,
          minBarThickness: 2,
          data: approved.value,
          backgroundColor: "#31A0D8"
        }
      ];
    });

    return {
      labels,
      dataSets,
      // useDisplay
      formatAmount,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
